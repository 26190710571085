var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { align: "center" } }, [
    _c("div", { staticClass: "text-h4" }, [_vm._v("Registro dei movimenti")]),
    _c("br"),
    _vm._v(" Preventivo N. " + _vm._s(_vm.formPreventivo.preventivo.id) + " "),
    _c("br"),
    _c("hr"),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "q-timeline",
          {
            staticClass: "col-md-6 offset-md-3",
            attrs: { layout: "comfortable", side: "right", color: "secondary" },
          },
          _vm._l(_vm.registro_movimenti.movimenti, function (movimento, index) {
            return _c(
              "q-timeline-entry",
              { key: index, attrs: { subtitle: movimento.dataMovimento } },
              [
                _c(
                  "q-list",
                  { attrs: { bordered: "" } },
                  [
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(_vm._s(movimento.personaOperativa)),
                            ]),
                            _c("q-item-label", [
                              _vm._v(_vm._s(movimento.descrizione)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
    _c("br"),
    _c("br"),
    _c("hr"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-md-4", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "INDIETRO",
              color: "blue-grey",
              icon: "undo",
              size: "md",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onClickIndietro()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }